function cookieCnil(){
    if ($.cookie('cookieCnil') === undefined) {
        $('#cookieCnil').fadeIn();
        $('#cookie_btn_ok').click(function(e){
            e.preventDefault();
            $('#cookieCnil').fadeOut();
            $.cookie('cookieCnil', "viewed", {expires: 365});
        });

        $('#cookie_btn_refuser').click(function(e){
            e.preventDefault();
            $('#cookieCnil').fadeOut();
            $.cookie('cookieCnil', "viewed", {expires: 365});
            $.cookie('cookieRefuser', "1", {expires: 365});
        });
    }
}

$(function () {
    cookieCnil(); // Cookie CNIL

    //CONTACT FORM
    $('.contact-message textarea, .these-message textarea').focus(function(){
        $(this).css("height", "195px");
    });

    //MENU RESPONSIVE
    $('#navButtonRwd').click(function(e){
        e.preventDefault();
        $(this).toggleClass('menu__btn--opened');
        $('.menu__list').toggleClass('menu__nav--opened');
        $('#nav').toggleClass('menu--opened');
    });
});


//FONCTION AFFIX MENU

var offset = 70;

    function affix() {

        if (window.matchMedia("(min-width: 992px)").matches) {
            // Fit affix width to its parent's width
            var $affixElement = $('div[data-spy="affix"]');
            $affixElement.width($affixElement.parent().width());

        }
        else {
            $('div[data-spy="affix"]').width('100%');
        }
    }

    // Call to function on DOM ready
    affix();

    // Call on scroll or resize
    $(window).on('scroll resize', function() {
        affix();

    });

    //FIXED AFTER ANIMATION
    setInterval(function(){
        $(".sidebar").css({"position": "fixed", "animation-fill-mode": "none", "opacity": "1"});
    }, 1000);

/*MAP ACF*/
(function($) {
    /*
     *  new_map
     *
     *  This function will render a Google Map onto the selected jQuery element
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$el (jQuery element)
     *  @return	n/a
     */
    function new_map( $el ) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom		: 16,
            center		: new google.maps.LatLng(0, 0),
            mapTypeId	: google.maps.MapTypeId.ROADMAP
        };


        // create map
        var map = new google.maps.Map( $el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function(){

            add_marker( $(this), map );

        });
        // center map
        center_map( map );
        // return
        return map;
    }
    /*
     *  add_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */
    function add_marker( $marker, map ) {
        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        // create marker
        var marker = new google.maps.Marker({
            position	: latlng,
            map			: map
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() )
        {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content		: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {

                infowindow.open( map, marker );

            });
        }

    }

    /*
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    function center_map( map ) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

        });

        // only 1 marker?
        if( map.markers.length == 1 )
        {
            // set center of map
            map.setCenter( bounds.getCenter() );
            map.setZoom( 16 );
        }
        else
        {
            // fit to bounds
            map.fitBounds( bounds );
        }

    }

    /*
     *  document ready
     *
     *  This function will render each map when the document is ready (page has loaded)
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	5.0.0
     *
     *  @param	n/a
     *  @return	n/a
     */
// global var
    var map = null;

    $(document).ready(function(){

        $('.acf-map').each(function(){

            // create map
            map = new_map( $(this) );

        });

    });

})(jQuery);
